import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ClockContainer = styled.div`
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
  background-color: #F6F6F6;
  width: 100%;
`;

const TimeContainer = styled.div`
    position: absolute;
    margin-top: -150px;
    font-size: 240px; /* Increase font size for time */
    font-weight: bold; /* Make the time bold */
    color: #333333;
`;

const DateContainer = styled.div`
    position: absolute;
    font-size: 80px; /* Decrease font size for date */
    color: #990100; /* Change color for date */
    margin-top: 200px;
`;

const DigitalClock = () => {
    const [currentTime, setCurrentTime] = useState(new window.Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new window.Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatDate = (date) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    const formatTime = (date) => {
        return date.toLocaleTimeString();
    };

    return (
        <ClockContainer>
            <TimeContainer>
                {formatTime(currentTime)}
            </TimeContainer>
            <DateContainer>{formatDate(currentTime)}</DateContainer>
        </ClockContainer>
    );
}

export default DigitalClock;