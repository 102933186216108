import React, { useState } from 'react';
import styled from 'styled-components';
import Search from './Search';  // Your existing Search component
import SearchAllDocuments from './SearchAllDocuments';  // Your existing SearchAllDocuments component

// Styled components
const TabContainer = styled.div`
    display: flex;
    justify-content: center;  // Centers the tabs in the view horizontally
    border-bottom: 1px solid #ccc;
    background-color: #f0f0f0;
`;

const TabButton = styled.button`
    flex: 1;
    padding: 10px 20px;  // Reduced padding for a more compact appearance
    font-size: 16px;  // Smaller font size for a less bulky appearance
    cursor: pointer;
    border: none;
    background-color: inherit;
    transition: background-color 0.3s;
    border-bottom: 3px solid transparent;  // Maintains the bottom border for active indication

    &:hover {
        background-color: #ddd;  // Slightly darker on hover for better interaction feedback
    }

    &.active {
        border-bottom-color: #2a9fd6;  // Blue to indicate active status
        color: #2a9fd6;  // Makes the text color blue for active tab
    }
`;

const ContentContainer = styled.div`
    padding: 6px 12px;
`;

function SearchTabs({ documentIds, singleDocumentId, setSelectedDocumentId }) {
    const [activeTab, setActiveTab] = useState('singleSearch');

    return (
        <div>
            <TabContainer>
                <TabButton
                    className={activeTab === 'singleSearch' ? 'active' : ''}
                    onClick={() => setActiveTab('singleSearch')}
                    style={{
                        color: activeTab === 'multiSearch' ? 'blue' : 'black'  // Changes text color based on active state
                    }}
                >
                    单文档搜索
                </TabButton>
                <TabButton
                    className={activeTab === 'multiSearch' ? 'active' : ''}
                    onClick={() => setActiveTab('multiSearch')}
                    style={{
                        color: activeTab === 'multiSearch' ? 'blue' : 'black'  // Changes text color based on active state
                    }}
                >
                    全文档搜索
                </TabButton>
            </TabContainer>

            <ContentContainer>
                {activeTab === 'singleSearch' && <Search 
                    documentId={singleDocumentId} 
                />}
                {activeTab === 'multiSearch' && <SearchAllDocuments 
                    documentIds={documentIds} 
                    setSelectedDocumentId={setSelectedDocumentId}
                />}
            </ContentContainer>
        </div>
    );
}

export default SearchTabs;
