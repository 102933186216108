import React, { useState, useEffect } from 'react';
import {
    Container, 
    Content, 
    SearchContainer, 
} from './StyledComponents';
import Sidebar from './Sidebar';
import MarkdownDisplay from './MarkdownDisplay';
import Textbox from './Textbox';
import AuthPopup from './Popup';
import configurations from './configurations.json';
import forwardRequest from './utilities/ForwardRequest';
import SearchTabs from './search/SearchTabs';

function LigteningHomePage() {
    const [selectedDocumentId, setSelectedDocumentId] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [text, setText] = useState('');
    const [title, setTitle] = useState('');

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    // 登录
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
            setShowPopup(false);  // 确保如果已经登录则不显示弹窗
        }
    }, []);

    const handleLogin = (token, userId) => {
        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
        setIsLoggedIn(true);
        setShowPopup(false);
    };

    // 登出
    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setShowPopup(true);  // 登出后显式显示弹窗
        window.location.reload();
    };

    useEffect(() => {
        const fetchDocumentDetails = async () => {
            if (!selectedDocumentId) {
                setTitle('');
                setText('');
                return;
            }
    
            const token = localStorage.getItem('token');
            if (!token) {
                console.error("未找到身份验证令牌");
                return;
            }
    
            const body = {
                body: {} // GET 请求的空请求体
            };
    
            try {
                const data = await forwardRequest(
                    `${configurations.endpoints.read}${selectedDocumentId}`, 
                    'GET',
                    body
                );
                if (data.status && data.data) {
                    setTitle(data.data.title);
                    setText(data.data.full_text);
                } else {
                    throw new Error('加载文档失败');
                }
            } catch (err) {
                console.error("获取文档错误:", err);
                alert(err.message);
            }
        };
    
        fetchDocumentDetails();
    }, [selectedDocumentId]);  // 依赖数组包含 selectedDocumentId

    // 每当有变化表明需要刷新时获取文档
    useEffect(() => {
        fetchDocuments();
    }, [selectedDocumentId]);

    const fetchDocuments = async () => {
        try {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');
            if (!token || !userId) {
                console.error("未找到身份验证详细信息");
                return;
            }
    
            // 步骤 1: 获取文档 ID
            const response = await fetch(
                `${configurations.base_url}${configurations.endpoints.available_resources}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: token, user_id: userId })
            });
    
            const data = await response.json();
            if (!data.status) {
                console.error('获取文档失败:', data.message);
                return;
            }
    
            const documentIds = data.data.collections.concat(data.data.uncategorized_documents);
    
            // 步骤 2: 使用转发 API 通过 ID 获取文档标题
            const titlesData = await forwardRequest(
                configurations.endpoints.retrieve_titles_by_ids,
                "POST",
                { document_ids: documentIds }
            );
    
            if (!titlesData.status) {
                console.error('检索文档标题失败:', titlesData.message);
                return;
            }
    
            // 设置包含标题数据的文档状态
            setDocuments(titlesData.data);
    
        } catch (err) {
            console.error("获取文档错误:", err);
        }
    };

    // 处理文档删除的函数
    const deleteDocument = async (documentId) => {
        try {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');
            if (!token || !userId) {
                console.error("未找到身份验证详细信息");
                return;
            }

            const forwardBody = {
                document_id: documentId
            };

            // 步骤 1: 通过转发 API 删除文档
            const forwardResponse = await forwardRequest(
                configurations.endpoints.delete,
                "DELETE",
                forwardBody
            );

            if (!forwardResponse.status) {
                console.error('通过转发 API 删除文档错误:', forwardResponse.message);
                return;
            }

            // 步骤 2: 直接从 MySQL 数据库中删除文档
            const deleteBody = {
                token: token,
                user_id: userId,
                document_id: documentId
            };

            const response = await fetch(
                `${configurations.base_url}${configurations.endpoints.delete_single_document}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(deleteBody)
            });
            const result = await response.json();
            if (result.status) {
                alert('文档已成功删除。');
                if (selectedDocumentId === documentId) {
                    setSelectedDocumentId(null);
                    setText('');
                    setTitle('');
                }
                fetchDocuments();  // 删除后刷新文档
            } else {
                console.error('从 MySQL 删除文档错误:', result.message);
            }
        } catch (error) {
            console.error("删除文档过程中出错:", error);
        }
    };

    return (
        <Container>
            <AuthPopup isOpen={!isLoggedIn} close={() => setShowPopup(false)} onLogin={handleLogin} />
            <Sidebar
                documents={documents}
                setSelectedDocumentId={setSelectedDocumentId}
                deleteDocument={deleteDocument}
                onLogout={handleLogout}
            />
            <Content>
                <Textbox
                    documentId={selectedDocumentId}
                    setDocumentId={setSelectedDocumentId}
                    text={text}
                    setText={setText}
                    title={title}
                    setTitle={setTitle}
                    fetchDocuments={fetchDocuments}
                />
                <button onClick={() => setSelectedDocumentId(null)}>新建文档</button>
                <MarkdownDisplay text={text}></MarkdownDisplay>
            </Content>
            <SearchContainer>
                {/* {selectedDocumentId && <Search documentId={selectedDocumentId} />} */}
                <SearchTabs 
                    documentIds={documents} 
                    singleDocumentId={selectedDocumentId} 
                    setSelectedDocumentId={setSelectedDocumentId}
                />
            </SearchContainer>
        </Container>
    );
}

export default LigteningHomePage;