import React, { useState } from 'react';
import { SearchContainer, SearchBar, Results } from '../StyledComponents';
import { forwardRequest } from '../utilities/ForwardRequest'; // Assuming this utility handles the API requests
import configurations from '../configurations.json';

function SearchAllDocuments({ setSelectedDocumentId, documentIds }) {
    const [inputText, setInputText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleMultiSearch = async () => {
        setLoading(true);
        const results = [];

        // Prepare requests for each document ID using the forwardRequest utility
    const requests = documentIds.map(document =>
        forwardRequest(
                `${configurations.endpoints.vector_search}`, 
                'POST',
                {
                    document_id: document.id,  // Ensure you are using document.id here
                    input_text: inputText,
                    top_n: 10
                }
        ).then(response => {
                if (response.status) {
                    // Map the response data to include titles directly from documentIds
                    const enrichedData = response.data.map(item => ({
                        ...item,
                        title: document.title, // Include the title for each document
                        document_id: document.id // Include document id for clarity
                    }));
                    results.push(...enrichedData);
                } else {
                    console.error('Search failed:', response.message);
                }
            })
        );

        try {
            // Execute all requests concurrently and handle the response
            await Promise.all(requests);
            // Sort results by relevance
            results.sort((a, b) => b.relevance - a.relevance);
            setSearchResults(results);
        } catch (error) {
            console.error('Multi-document search failed:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <SearchContainer>
            <SearchBar>
                <input
                    type="text"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    placeholder="描述您想寻找的内容"
                />
                <button onClick={handleMultiSearch}>智能搜索</button>
            </SearchBar>
            <Results>
                {loading && <div>Loading results...</div>}
                <ul>
                    {searchResults.map((result, index) => (
                        <li key={index} onClick={() => setSelectedDocumentId(result.document_id)}
                            style={{ cursor: 'pointer', marginBottom: '20px' }}>
                            <div style={{ marginBottom: '10px' }}>
                                <p>{result.content}</p>
                                <h4>关联度:</h4>
                                <p>{result.relevance.toFixed(2)}</p>
                                <h4>跳转来源:</h4>
                                <p>{result.title}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </Results>
        </SearchContainer>
    );
}

export default SearchAllDocuments;