import React, { useState } from 'react';
import { SearchContainer, SearchBar, Results } from '../StyledComponents';
import configurations from '../configurations.json';
import { forwardRequest } from '../utilities/ForwardRequest'; // Assuming you have a utility file for API calls

function Search({ documentId }) {
    const [inputText, setInputText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSearch = async () => {
        setLoading(true);
        try {
            const data = await forwardRequest(
                configurations.endpoints.vector_search,
                'POST',
                {
                    document_id: documentId,
                    input_text: inputText,
                    top_n: 10
                }
            );

            if (data.status) {
                setSearchResults(data.data);
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.error('Search failed:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <SearchContainer>
            <SearchBar>
                <input
                    type="text"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    placeholder="描述您想寻找的内容"
                />
                <button onClick={handleSearch}>智能搜索</button>
            </SearchBar>
            <Results>
                {loading && <div>Loading results...</div>}
                <ul>
                    {searchResults.map((result, index) => (
                        <li key={index}>{result.relevance} - {result.content}</li>
                    ))}
                </ul>
            </Results>
        </SearchContainer>
    );
}

export default Search;