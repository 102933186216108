import React, { useState } from 'react';
import LoginForm from './Login';
import RegistrationForm from './Registration';

function AuthPopup({ isOpen, onLogin }) {
    const [showLogin, setShowLogin] = useState(true);  // Toggle between Login and Registration forms

    if (!isOpen) return null;

    return (
        <div style={{ 
            position: 'fixed', 
            top: 0, 
            left: 0, 
            width: '100%', 
            height: '100%', 
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center' 
        }}>
            <div style={{ 
                background: 'white', 
                padding: '20px', 
                width: 'auto',
                height: 'auto'
            }}>
                <h1 style={
                    {
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center'
                    }
                }>Ligtening</h1>
                <p style={
                    {
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center'
                    }
                }>文档快速检索</p>
                {showLogin ? (
                    <>
                        <LoginForm onLogin={onLogin} />
                        <button onClick={() => setShowLogin(false)}>还没注册吗？点击这里注册！</button>
                    </>
                ) : (
                    <>
                        <RegistrationForm onLogin={onLogin} />  {/* Changed from onRegister to onLogin */}
                        <button onClick={() => setShowLogin(true)}>已经注册好了？点击这里登录！</button>
                    </>
                )}
            </div>
        </div>
    );
}

export default AuthPopup;