//App.js
import { Route, Routes } from 'react-router-dom';
import './App.css';
import LigteningHomePage from './LigtheningHomePage';
import DigitalClock from './DigitalClock';
import Home from './Home';

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path='/' element={<Home/>} />
                <Route path='/lightening' element={<LigteningHomePage/>} />
                <Route path='/digital_clock' element={<DigitalClock/>} />
            </Routes>
        </div>
    );
}

export default App;