import React from 'react';

function MembershipCard({ membershipCard }) {
    const expirationDate = membershipCard.membership_type === 'Free' 
        ? '无限' 
        : new Date(membershipCard.expiration_date).toLocaleString();

    return (
        <div style={{ marginTop: '20px', padding: '10px', borderTop: '1px solid #ccc' }}>
            <h3>用户信息</h3>
            <p>类型: {membershipCard.membership_type}</p>
            <p>有效期至: {expirationDate}</p>
            <p>可存文档数量: {membershipCard.document_limit}</p>
        </div>
    );
}

export default MembershipCard;