import React, { useState } from 'react';
import styled from 'styled-components';
import configurations from './configurations.json';

const UploadContainer = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
`;

const Loader = styled.div`
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const UploadComponent = ({ setDocumentId, fetchDocuments }) => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (event) => {
        setSelectedFiles(Array.from(event.target.files));
    };

    const uploadFiles = async () => {
        if (selectedFiles.length === 0) {
            alert('Please select at least one file.');
            return;
        }

        setLoading(true);
        setResponse(null);

        try {
            for (let i = 0; i < selectedFiles.length; i++) {
                const formData = new FormData();
                const jsonBlob = new Blob([JSON.stringify({
                    endpoint: `${configurations.functionality_layer}${configurations.endpoints.create}`, // replace with actual endpoint
                    token: localStorage.token,       // replace with actual token
                    maximum_text_split_size: 256
                })], { type: 'application/json' });

                formData.append('json', jsonBlob);
                formData.append('file', selectedFiles[i]);

                const res = await fetch(
                    `${configurations.base_url}${configurations.endpoints.create_document_by_single_file}`, { // replace with your actual API endpoint
                    method: 'POST',
                    body: formData
                });

                const result = await res.json();

                if (result.status) {
                    const newDocumentId = result.document_id;
                    setDocumentId(newDocumentId);
                    await logDocumentToMySQL(newDocumentId);
                } else {
                    throw new Error(result.message);
                }
            }

            fetchDocuments();
            alert('All files have been uploaded successfully.');

        } catch (error) {
            setResponse({ error: error.message });
        } finally {
            setLoading(false);
            setSelectedFiles([]); // Reset selected files
        }
    };

    const logDocumentToMySQL = async (docId) => {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        if (!token || !userId || !docId) {
            console.error("缺少记录文档所需的信息");
            return;
        }

        const logBody = {
            token: token,
            user_id: userId,
            document_id: docId
        };

        try {
            const response = await fetch(
                `${configurations.base_url}${configurations.endpoints.log_single_document}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(logBody)
            });
            const result = await response.json();
            if (!result.status) {
                console.error('记录文档失败:', result.message);
            }
        } catch (error) {
            console.error("记录文档到 MySQL 时出错:", error);
        }
    };

    return (
        <UploadContainer>
            <h1>上传文件</h1>
            <p>当前支持格式为: txt</p>
            <p>当前最大支持单个文件大小为: 10 MB</p>
            <input type="file" multiple onChange={handleFileChange} key={selectedFiles.length ? 'notLoaded' : 'loaded'} />
            {selectedFiles.length > 0 && (
                <p>{selectedFiles.length} file(s) selected</p>
            )}
            <button onClick={uploadFiles} disabled={loading || selectedFiles.length === 0}>上传</button>
            {loading && (
                <div>
                    <p>上传中...</p>
                    <Loader />
                </div>
            )}
            {response && response.error && (
                <div>
                    <p>Error: {response.error}</p>
                </div>
            )}
        </UploadContainer>
    );
};

export default UploadComponent;