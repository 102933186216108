import React, { useState } from 'react';
import forwardRequest from './utilities/ForwardRequest';
import configurations from './configurations.json';
import UploadComponent from './UploadComponent';

function Textbox({ documentId, setDocumentId, text, setText, title, setTitle, fetchDocuments }) {
    const [isLoading, setIsLoading] = useState(false);
    const [clickUpload, setClickUpload] = useState(false);

    const handleSave = async () => {
        let apiURL, body;

        if (documentId) {
            // 更新现有文档
            apiURL = configurations.endpoints.update;
            body = {
                updated_parameters: {
                    title: title,
                    full_text: text,
                    maximum_text_split_size: 256
                },
                document_id: documentId
            };
        } else {
            // 创建新文档
            apiURL = configurations.endpoints.create;
            body = {
                title: title,
                full_text: text,
                maximum_text_split_size: 256
            };
        }

        setIsLoading(true);
        try {
            if (documentId) {
                // 首先删除旧记录
                await deleteDocumentFromMySQL(documentId);
            }

            const data = await forwardRequest(apiURL, "POST", body);
            if (data.status) {
                alert('文档保存成功');
                const newDocumentId = data.document_id || documentId;
                if (!documentId) {
                    setDocumentId(newDocumentId);  // 更新新文档的文档ID
                }
                // 将文档记录到 MySQL 数据库
                await logDocumentToMySQL(newDocumentId);

                fetchDocuments();
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.error('错误:', error);
            alert(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteDocumentFromMySQL = async (docId) => {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        if (!token || !userId || !docId) {
            console.error("缺少删除文档所需的信息");
            return;
        }

        const deleteBody = {
            token: token,
            user_id: userId,
            document_id: docId
        };

        try {
            const response = await fetch(
                `${configurations.base_url}${configurations.endpoints.delete_single_document}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(deleteBody)
            });
            const result = await response.json();
            if (!result.status) {
                console.error('删除文档失败:', result.message);
            }
        } catch (error) {
            console.error("从 MySQL 删除文档时出错:", error);
        }
    };

    const logDocumentToMySQL = async (docId) => {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        if (!token || !userId || !docId) {
            console.error("缺少记录文档所需的信息");
            return;
        }

        const logBody = {
            token: token,
            user_id: userId,
            document_id: docId
        };

        try {
            const response = await fetch(
                `${configurations.base_url}${configurations.endpoints.log_single_document}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(logBody)
            });
            const result = await response.json();
            if (!result.status) {
                console.error('记录文档失败:', result.message);
            }
        } catch (error) {
            console.error("记录文档到 MySQL 时出错:", error);
        }
    };

    return (
        <div>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="输入标题" />
            <textarea 
                value={text} 
                onChange={(e) => setText(e.target.value)} 
                placeholder="在这里输入文本..." 
            />
            <button onClick={handleSave} disabled={isLoading}>
                {documentId ? '更新文档' : '创建文档'}
            </button>
            <button onClick={() => setClickUpload(clickUpload ? false : true)}>上传文档</button>
            {clickUpload && <UploadComponent setDocumentId={setDocumentId} fetchDocuments={fetchDocuments} />}
        </div>
    );
}

export default Textbox;