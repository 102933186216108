import React, { useState, useEffect } from 'react';
import { SidebarStyle, ListItem } from './StyledComponents';
import MembershipCard from './MembershipCard';
import configurations from './configurations.json';

function Sidebar({ setSelectedDocumentId, documents, deleteDocument, onLogout }) {
    const [membershipCard, setMembershipCard] = useState(null);

    useEffect(() => {
        
        // Fetch membership information
        fetch(`${configurations.base_url}${configurations.endpoints.get_user_membership_information}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: localStorage.getItem('userId')
                })
            }
        )
        .then(response => response.json())
        .then(data => {
            if (data.status) {
                setMembershipCard(data.data);
            } else {
                console.error('Failed to fetch membership information');
            }
        })
        .catch(error => {
            console.error('Error fetching membership information:', error);
        });
    }, []);

    return (
        <SidebarStyle>
            <h2>Documents</h2>
            {documents.length > 0 ? (
                <ul>
                    {documents.map(document => (
                        <ListItem key={document.id} onClick={() => setSelectedDocumentId(document.id)}>
                            <span>
                                {document.title}
                            </span>
                            <button
                                style={{
                                    width: '25px',
                                    height: '25px',
                                    textAlign: 'center'
                                }}
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent the setSelectedDocumentId from firing
                                    deleteDocument(document.id);
                                }}
                            >X</button>
                        </ListItem>
                    ))}
                </ul>
            ) : (
                <div>No documents available</div>
            )}
            <button 
                style={{
                    width: '100%',
                    padding: '10px 0',
                    marginTop: '20px', // Adjust space as needed
                    backgroundColor: '#f44336', // Red color for the logout button
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer'
                }} 
                onClick={onLogout}
            >
                Logout
            </button>
            {membershipCard && <MembershipCard membershipCard={membershipCard} />}
            <div style={{ marginTop: '20px', padding: '10px', borderTop: '1px solid #ccc' }}>
                <p>为了保证服务器正常运转，限制了可存文档数量。想提高数量可以联系我，VX：baoxinyu2007</p>
            </div>
        </SidebarStyle>
    );
}

export default Sidebar;