import styled from "styled-components";


export const Container = styled.div`
	display: flex;
	height: 100vh;
	background-color: var(--background-color);
	color: var(--text-color);
`;

export const SidebarStyle = styled.div`
	flex: 1;
	background-color: var(--sidebar-bg-color);
	padding: 20px;
	overflow: auto;
	flex: 0 0 250px;
`;

export const Content = styled.div`
	flex: 3;
	padding: 20px;
	overflow: auto;
`;

export const SearchContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	background-color: var(--sidebar-bg-color);
	padding: 20px;
	overflow: auto;
`;

export const SearchBar = styled.div`
	margin-bottom: 20px;
`;

export const Results = styled.div`
	flex: 1;
	overflow: auto;
`;

export const ListItem = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	span {
		flex: 1;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-right: 10px;
	}
	button {
		padding: 5px 10px;
		background-color: #ff4d4f;
		color: white;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		font-size: 14px;
	}
`;