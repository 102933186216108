import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const AppContainer = styled.div`
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
  background-color: #F6F6F6;
  width: 100%;
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: #F6F6F6;
  color: #333333;
  font-size: 8pt;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const FooterLink = styled.a`
  text-decoration: none; /* Remove the underline */
  color: #333333; /* Ensure the text color matches the rest of the footer */
  &:hover {
    color: #000000; /* Optional: Change color on hover */
  }
`;

const Logo = styled.img`
  width: 20px; /* Adjust the width to make the logo smaller */
  height: auto; /* Maintain aspect ratio */
`;

const ScrollableHeaderContainer = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  padding: 50px 10px;
  margin-top: -100px;
`;

const HeaderItem = styled.div`
  display: inline-block;
  min-width: 100%;
  margin: 0px 10px;
  font-size: 18px;
  color: #333333;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  h1, h2 {
    margin: 0;
  }

  h1 {
    color: #333333;
  }

  h2 {
    color: #B90504;
  }

  a {
	text-decoration: none;
  }
`;

const TransparentBanner = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #F6F6F6;
  padding: 10px 20px;
  text-align: right;
`;

const StyledLink = styled.a`
  margin: 0 20px;
  color: #990100;
  text-decoration: none;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
  font-weight: bold;

  &:hover {
    background-color: #990100;
    color: #E8E8E8;
    padding: 15px 25px;
  }
`;

const DotContainer = styled.div`
  position: absolute;
  bottom: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Dot = styled.div`
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: ${props => (props.active ? '#990100' : '#bbb')};
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
`;

const headers = [
	{
		title: '分享有用的技术',
		subtitle: 'Share what\'s (hopefully) useful',
		link: '/digital_clock'
	},
	{
		title: 'Lightening',
		subtitle: '瞬间从文章中找到重点的神器',
		link: '/lightening'
	},
	{
		title: 'Bilibili',
		subtitle: '尝试做点有用的视频',
		link: 'https://space.bilibili.com/21443876'
	},
];

const Home = () => {
	const scrollRef = useRef(null);
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		const scrollContainer = scrollRef.current;
		const scrollStep = scrollContainer.offsetWidth;
		const scrollInterval = 3000;

		const scrollHeaders = () => {
			setCurrentIndex(prevIndex => {
				const newIndex = (prevIndex + 1) % headers.length;
				scrollContainer.scrollTo({
					left: newIndex * scrollStep,
					behavior: 'smooth',
				});
				return newIndex;
			});
		};

		const interval = setInterval(scrollHeaders, scrollInterval);
		return () => clearInterval(interval);
	}, []);

	const handleDotClick = index => {
		const scrollContainer = scrollRef.current;
		const scrollStep = scrollContainer.offsetWidth;
		scrollContainer.scrollTo({
			left: index * scrollStep,
			behavior: 'smooth',
		});
		setCurrentIndex(index);
	};

	return (
		<>
			<TransparentBanner>
				<StyledLink href="/lightening" target="_blank" rel="noopener noreferrer">Lightening</StyledLink>
				<StyledLink href="https://space.bilibili.com/21443876" target="_blank" rel="noopener noreferrer">Bilibili</StyledLink>
				<StyledLink href="https://github.com/AspadaX?tab=repositories" target="_blank" rel="noopener noreferrer">Github</StyledLink>
			</TransparentBanner>
			<AppContainer>
				<ScrollableHeaderContainer ref={scrollRef}>
					{headers.map((header, index) => (
						<HeaderItem key={index}>
							<a href={header.link} target="_blank" rel="noopener noreferrer"><h1>{header.title}</h1></a>
							<h2>{header.subtitle}</h2>
						</HeaderItem>
					))}
				</ScrollableHeaderContainer>
				<DotContainer>
					{headers.map((_, index) => (
						<Dot
							key={index}
							active={index === currentIndex}
							onClick={() => handleDotClick(index)}
						/>
					))}
				</DotContainer>
				<Footer>
					<p>© 2024 Xinyu Bao. All rights reserved.</p>
          <Logo class="logos" src="/备案图标.png"/>
          <FooterLink href="https://beian.mps.gov.cn/#/query/webSearch?code=32010602011952" rel="noreferrer" target="_blank">苏公网安备32010602011952</FooterLink>
          <FooterLink href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2024116800号-1</FooterLink>
				</Footer>
			</AppContainer>
		</>
	);
}

export default Home;