import React, { useState } from 'react';
import configurations from './configurations.json';

function LoginForm({ onLogin }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(
                `${configurations.base_url}${configurations.endpoints.login}`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email, password })
                }
            );
            const data = await response.json();
            if (data.status && data.data.token) {
                // 假设 data.data.id 是服务器返回的用户 ID
                onLogin(data.data.token, data.data.id);
                window.location.reload();
            } else {
                throw new Error(data.message || "登录失败");
            }
        } catch (error) {
            alert("登录错误: " + error.message);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="email"
                placeholder="邮箱"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <input
                type="password"
                placeholder="密码"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <button type="submit">登录</button>
        </form>
    );
}

export default LoginForm;