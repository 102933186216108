import React, { useState } from 'react';
import configurations from './configurations.json';

function RegistrationForm({ onLogin }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(
                `${configurations.base_url}${configurations.endpoints.register}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password })
            });
            const data = await response.json();
            if (data.status) {
                // 检查响应中是否同时包含 token 和用户 ID
                if (data.data.token && data.data.id) {
                    onLogin(data.data.token, data.data.id);
                    window.location.reload();
                } else {
                    throw new Error('注册成功但未返回 token 或用户 ID');
                }
            } else {
                throw new Error(data.message || "注册失败");
            }
        } catch (error) {
            alert("注册错误: " + error.message);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="email"
                placeholder="邮箱"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <input
                type="password"
                placeholder="密码"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <button type="submit">注册</button>
        </form>
    );
}

export default RegistrationForm;