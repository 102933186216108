const configurations = require('../configurations.json'); // 根据需要调整路径

export const forwardRequest = async (endpoint, method, body) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error("未找到身份验证令牌");
    }

    const response = await fetch(
        `${configurations.base_url}${configurations.endpoints.forward}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            token,
            endpoint: `${configurations.functionality_layer}${endpoint}`,
            method,
            body
        })
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || "请求处理失败");
    }

    return data;
};

export default forwardRequest;