import React from 'react';
import ReactMarkdown from 'react-markdown';


function MarkdownDisplay({text}) {
    return (
        <div className='markdown-container'>
            <ReactMarkdown>{text}</ReactMarkdown>
        </div>
    );
}

export default MarkdownDisplay;